import { Component, OnInit } from '@angular/core';
import { google } from "google-maps";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Router} from '@angular/router'
import {finalize} from 'rxjs/operators';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {



  constructor(private http: HttpClient, private router:Router) { 
  }

  ngOnInit() {
    
    }

    sendmessage = new FormGroup({
      name: new FormControl('', Validators.required),
      subject: new FormControl('', Validators.required),
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      message: new FormControl('',Validators.required)
      
     });

    //  get firstname(){
    //   return this.form.get('email')
    // }
    get f() { return this.sendmessage.controls; }

    onSubmitContact(){
      
      const API_KEY = '265ec6b4ca7b3baede32c609a4cdd133-us18';
      const AUDIENCE_ID = '41315e7f13';
      const SEND_WELCOME = false;
      const NAME = this.sendmessage.value.name;
      const EMAIL=this.sendmessage.value.email;
      const SUBJECT=this.sendmessage.value.subject;
      const MESSAGE=this.sendmessage.value.message;
      const URL = "https://us18.api.mailchimp.com/2.0/lists/subscribe.json?apikey=" + API_KEY + "&id=" + AUDIENCE_ID + "&email[email]=" + EMAIL + "&merge_vars[NAME]=" + NAME + "&merge_vars[SUB]=" + SUBJECT + "&merge_vars[MSG]=" + MESSAGE + "&double_optin=false&send_welcome=" + SEND_WELCOME;
     
       this.http.get(URL).subscribe((response) => {
        this.router.navigate(['/'])
        console.log('response', response);
        
        },(error)=>{
 
          console.log('error occured',error);
        })
       }

       scrollWin()
       {
         window.scrollTo(0,800)
       }
       ourMission()
       {
         window.scrollTo(0,800)
       }
       ourVision()
       {
         window.scrollTo(0,800)
       }
       ourValues()
       {
         window.scrollTo(0,800)
       }
       registartion()
       {
         window.scrollTo(0,800)
       }
       corporate()
       {
         window.scrollTo(0,800)
       }
    }
  
  


	

