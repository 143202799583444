import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-msme',
  templateUrl: './msme.component.html',
  styleUrls: ['./msme.component.css']
})
export class MSMEComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
