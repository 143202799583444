import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-article-corona',
  templateUrl: './article-corona.component.html',
  styleUrls: ['./article-corona.component.css']
})
export class ArticleCoronaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
