import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ankit',
  templateUrl: './ankit.component.html',
  styleUrls: ['./ankit.component.css']
})
export class AnkitComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
