import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';



@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  
  constructor(private http: HttpClient, private router: Router) {

  
    // call HTTP get request
   
  }
 
  ngOnInit() {
  }
  form=new FormGroup({
	email: new FormControl('', [
		Validators.required,
		Validators.email
	  ]),
  })
    
  get email(){
	return this.form.get('email')
  }
  submit() {

	const API_KEY = '9a6f9da94b782a193f94524d13c7f1b0-us4';
	const AUDIENCE_ID = 'c86bf544b7';
  const SEND_WELCOME = false;
    // subscriber details
  const SUBSCRIBER_EMAIL = this.email.value;
  const FNAME = ' ';
  const LNAME = ' ';
	const URL = "https://us4.api.mailchimp.com/2.0/lists/subscribe.json?apikey=" + API_KEY + "&id=" + AUDIENCE_ID + "&email[email]=" + SUBSCRIBER_EMAIL + "&merge_vars[FNAME]=" + FNAME + "&merge_vars[LNAME]=" + LNAME + "&double_optin=false&send_welcome=" + SEND_WELCOME;
	this.http.get(URL).subscribe((response) => {
    this.router.navigate(['/'])
		console.log('response', response);
		
	  },(error)=>{
		  
		  console.log('error occured',error);
	  })
	 }
  
	
    disclamier(){
      Swal.fire({
        title: 'Diclamier',
        width:600,
        text: 'To comply with regulations stipulated by the Code of Ethics prescribed by Institute of Chartered Accountants (ICAI) of India that does not permit solicitation in any form or manner, by accessing this site you acknowledge that you are seeking information of your own accord and volition and that no form of solicitation has taken place by Jaisinghani Jain & Associates or its members. This site (www.cajandj.com) should not be construed as an attempt to create a CA-client relationship of any manner. This site is meant to provide information to those who specifically request this information and does not purport to be exhaustive in relation to the information contained herein. Jaisinghani Jain & Associates does not assume any responsibility for any acts or omissions arising from the use of information provided on this site and visitors to this site are advised to seek independent professional guidance before proceeding to act on any information provided herein.',
        confirmButtonText: 'Proceed',
  
      });
    }
  }

